import { createI18n } from 'vue-i18n';
import enGB from '@/locales/en-GB.json';

// Define a schema for the translations based on en-gb.json as the master
type TranslationSchema = typeof enGB;

// See locales/README.md for more information on adding and maintaining language packs
const i18n = createI18n<[TranslationSchema], 'en-GB'>({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  legacy: false,
  messages: {
    "en-GB": enGB,
  },
});

export default i18n;
