import { createWebHistory, createRouter } from 'vue-router';
import paths from './paths';

const routes = [
  {
    path: paths.survey.path,
    name: paths.survey.name,
    component: () => import(/* webpackChunkName: "survey" */ '../views/Survey/Index.vue'),
    props: (route: any) => ({ preview: route.query.preview }),
  },
  {
    path: paths.travelplans.path,
    name: paths.travelplans.name,
    component: () => import(/* webpackChunkName: "travelplans" */ '../views/TravelPlan/index.vue'),
  },
  {
    path: paths.travelplansWidgetEmbed.path,
    name: paths.travelplansWidgetEmbed.name,
    component: () =>
      import(/* webpackChunkName: "travelplansembed" */ '../views/TravelPlan/Widget/index.vue'),
  },
  {
    path: paths.travelplansWidget.path,
    name: paths.travelplansWidget.name,
    component: () =>
      import(
        /* webpackChunkName: "travelplanswidget" */ '../views/TravelPlan/WidgetPage/index.vue'
      ),
  },
  {
    path: paths.unsubscribe.path,
    name: paths.unsubscribe.name,
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/Unsubscribe/Index.vue'),
    props: (route: any) => ({ mode: route.query.mode }),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/Error/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
