import { TravelPlanServiceType } from '@/assets/enum';
import { CycleCaloriesPerSecond, WalkingCaloriesPerSecond } from '@/assets/constants';

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isValidEmail = (rawString: string): boolean => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(rawString));
};

export const isValidLocation = (rawLocation: string): boolean => {
  const regex = /^\s*(.+)\s{(-?\d+\.\d+),(-?\d+\.\d+)}\s*$/;
  return regex.test(String(rawLocation));
};

export const isValidNumber = (rawNumber: string): boolean => {
  if (String(rawNumber).length > 20) return false;

  const regex = /^[\d+|.|\d+]+$/;
  const numberAndDotsOnly = regex.test(String(rawNumber));

  if (numberAndDotsOnly) {
    const tooManyDots = (String(rawNumber).match(/\./g) || []).length > 1;
    if (tooManyDots) return false;
    return true;
  } else {
    return false;
  }
};


export const getCalories = (serviceType: TravelPlanServiceType, duration: number): number => {
  switch (serviceType) {
    case 'Walk':
      return Math.round(WalkingCaloriesPerSecond * duration);
    case 'Bike':
      return Math.round(CycleCaloriesPerSecond * duration);
    default:
      return 0;
  }
};

export const contrastingColour = (hex: string): string => {
  let passedWithHash = false;

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
    passedWithHash = true;
  }

  let prefix = passedWithHash ? '#' : '';

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX Colour');
  }

  let red = parseInt(hex.slice(0, 2), 16);
  let green = parseInt(hex.slice(2, 4), 16);
  let blue = parseInt(hex.slice(4, 6), 16);

  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? `${prefix}000000` : `${prefix}FFFFFF`;
};

export const findTravelPlanError = (rawString: string): string => {
  // apparently this can't be manipulated on the server
  switch (rawString) {
    case "Cycling directions are in beta. Use caution – This route may contain streets that aren't suitable for cycling.":
      return "Use caution – This route may contain streets that aren't suited for bicycling.";
    case 'Walking directions are in beta. Use caution – This route may be missing pavements or pedestrian paths.':
      return 'Use caution – This route may be missing sidewalks or pedestrian paths.';
    default:
      return rawString;
  }
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const slugify = (inputStr: string) => {
  let str = inputStr.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

// Dynamic Width (Build Regex)
export const wrapText = (rawText: string, width: number) =>
  rawText.replace(new RegExp(`(?![^\\n]{1,${width}}$)([^\\n]{1,${width}})\\s`, 'g'), '$1\n');

type Env = 'localhost' | 'localdev' | 'development' | 'staging' | 'production' | 'aspire';
/**
 * Get the environment based on the hostname.
 * Duplicated in MW Dash
 */
export const getEnv = (): Env => {
  if (import.meta.env.MODE === 'localdev') return 'localdev';
  if (import.meta.env.MODE === 'aspire') return 'aspire';
  if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1')
  )
    return 'localhost';
  if (window.location.hostname.includes('development')) return 'development';
  if (window.location.hostname.includes('staging')) return 'staging';
  return 'production';
};
