export default {
  survey: {
    path: '/survey/:publicId/:contactId?',
    name: 'survey',
  },
  travelplans: {
    path: '/travel-plan/plan/:planId',
    name: 'travelplans',
  },
  travelplansWidget: {
    path: '/travel-plan/widget/:widgetId',
    name: 'travelplansWidget',
  },
  travelplansWidgetEmbed: {
    path: '/travel-plan/embed/:widgetId',
    name: 'travelplansWidgetEmbed',
  },
  unsubscribe: {
    path: '/unsubscribe/:contactId?',
    name: 'unsubscribe',
  },
};
